/* Utility: web notifications promo block
-------------------------------------------------- */

.web-promo-designer {
    display: none;
}

.web-promo-designer-modal {
    position: fixed;
    z-index: 1050;
    width: 600px;
    margin: 0 auto;
    transition: transform 0.5s;
}

.web-promo-designer-modal.toaster_top {
    top: 0;
    left: 0;
    right: 0;
    transform: translate(0, -105%);
}

.web-promo-designer-modal.toaster_bottom {
    bottom: 0;
    left: 0;
    right: 0;
    transform: translate(0, 105%);
}

.web-promo-designer-modal.toaster_left {
    left: 0;
    top: 25%;
    transform: translate(-105%, 0);
}

.web-promo-designer-modal.toaster_right {
    right: 0;
    top: 25%;
    transform: translate(105%, 0);
}

.web-promo-designer-modal.in {
    transform: translate(0, 0);
}

.web-promo-designer-modal .modal-dialog {
    margin: 0 auto;
}

.web-promo-designer .web-promo-designer-icon {
    font-size: 100px;
    text-align: center;
    opacity: 0.5;
}

.web-promo-designer .headline {
    font-weight: bold;
    margin-top: 0;
}

.web-promo-designer .topics-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    margin-bottom: 10px;
}

.web-promo-designer-modal .modal-content {
    border-radius: 0;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

.web-promo-designer .modal-dialog .web-promo-designer-buttons {
    text-align: center;
}

.web-promo-designer .modal-dialog .close-button,
.web-promo-designer .modal-dialog .sign-up {
    margin-left: 10px;
    margin-right: 10px;
}

.web-promo-designer .admin-alert {
    margin-bottom: 0;
    margin-top: 15px;
}